import Modal from "@components/modal/modal";
import { LinkButton, PrimaryButton } from "./button";
import { getRecommenderUrl } from "@lib/utilities/shared-utilities";
import { ProductCategoryConfig } from "@lib/model/product-category-config";
import { createContext, useContext, useState } from "react";
import eventTracker, { Events } from "@lib/tracking/event-tracker";

export const ReflowModalContext = createContext<ReflowModalContextValues>(null);

interface ReflowModalContextValues {
  activeReflowCategory: ProductCategoryConfig;
  setActiveReflowCategory: React.Dispatch<
    React.SetStateAction<ProductCategoryConfig>
  >;
  getCurrentQuestionId: (category: ProductCategoryConfig) => string;
  handleRecommenderClick: (
    category: ProductCategoryConfig,
    e: React.MouseEvent<HTMLAnchorElement>
  ) => void;
}

/**
 * A context provider that includes the Reflow Modal, and provides a function for
 * showing it.
 */
export const ReflowModalContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [activeReflowCategory, setActiveReflowCategory] = useState(null);

  const handleRecommenderClick = (
    category: ProductCategoryConfig,
    e: React.MouseEvent<HTMLAnchorElement>
  ) => {
    const storedCategory = window.sessionStorage.getItem(category.name);
    let currentQuestionId;
    if (storedCategory) {
      const decodedCategory = JSON.parse(storedCategory);
      currentQuestionId = decodedCategory.currentQuestionId;
    }

    if (storedCategory) {
      eventTracker.track(Events.RecommenderReflowUiOpen, {
        target_category: category.name,
      });
      e.preventDefault();
      setActiveReflowCategory(category);
    }
  };

  const getCurrentQuestionId = (category: ProductCategoryConfig) => {
    const storedCategory = window.sessionStorage.getItem(category.name);
    if (storedCategory) {
      const decodedCategory = JSON.parse(storedCategory);
      return decodedCategory.currentQuestionId;
    }
  };

  return (
    <ReflowModalContext.Provider
      value={{
        activeReflowCategory,
        setActiveReflowCategory,
        getCurrentQuestionId,
        handleRecommenderClick,
      }}
    >
      <ReflowModal />
      {children}
    </ReflowModalContext.Provider>
  );
};

/**
 * A modal UI that allows the user to either start over or return to a category.
 */
export const ReflowModal = () => {
  const {
    activeReflowCategory: category,
    setActiveReflowCategory,
    getCurrentQuestionId,
  } = useContext(ReflowModalContext);

  if (!category) {
    return null;
  }

  const open = category !== null;
  return (
    <Modal
      modalOpen={open}
      onClose={() => setActiveReflowCategory(null)}
      omitBottomButtons
    >
      Would you like to return to where you left off, or start over?
      <div className="flex gap-4 justify-center items-center mt-8">
        <LinkButton
          href={
            getRecommenderUrl(category, true) +
            `&q=${getCurrentQuestionId(category)}`
          }
          variant="outline"
          testId={`category-nav-card-return-link--${category.name}`}
          onClick={() => {
            setActiveReflowCategory(null);
            eventTracker.track(Events.RecommenderReflowUiReturn, {
              target_category: category.name,
            });
            window.location.href =
              getRecommenderUrl(category, true) +
              `&q=${getCurrentQuestionId(category)}`;
          }}
        >
          Return to where I left off
        </LinkButton>
        <PrimaryButton
          variant="solid"
          data-testid={`category-nav-card-start-over--${category.name}`}
          onClick={() => {
            sessionStorage.setItem(
              category.name,
              JSON.stringify({ skipSlash: true })
            );
            setActiveReflowCategory(null);
            eventTracker.track(Events.RecommenderReflowUiStartOver, {
              target_category: category.name,
            });
            window.location.href = getRecommenderUrl(category, true);
          }}
        >
          Start over
        </PrimaryButton>
      </div>
    </Modal>
  );
};
