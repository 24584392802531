import { Fragment, createContext } from "react";
import { Dialog, Transition } from "@headlessui/react";
import ModalWrapper from "@components/modal/modal-wrapper";
import { ArrowLeftCircleIcon, XCircleIcon } from "@heroicons/react/24/outline";
import { PrimaryButton, TrayButton } from "@components/common/button";

export interface ModalProps {
  children: React.ReactNode;
  onClose: () => void;
  onSubmit?: () => void;
  modalOpen: boolean;
  closeButtonText?: string;
  showCloseButton?: boolean;
  omitBottomButtons?: boolean;
  linkButtonText?: string;
  isConfirmation?: boolean;
  variant?: "xlarge" | "large" | "small" | "medium";
  title?: string;
  className?: string;
  dataTestId?: string;
  removeOverflowAutoClass?: boolean;
}

const defaultButtonText = {
  closeButtonText: "Got it",
};

/**
 * An X icon/button for closing the modal. It always displays for "small" modals.
 */
const XButton = (props: {
  onClose: () => void;
  className?: string;
  dataTestId?: string;
}) => {
  return (
    <button
      type="button"
      className={`h-6 w-6 ${props.className || ""}`}
      onClick={props.onClose}
      data-testid={props.dataTestId}
    >
      <span className="outline-0 sr-only">Close</span>
      <XCircleIcon aria-hidden="true" className="w-6" />
    </button>
  );
};

/**
 * A button that says "back". It displays only on large modal canvases, and only
 * at small breakpoints.
 */
const BackButton = (props: { onClose: () => void; className?: string }) => {
  return (
    <TrayButton
      onClick={props.onClose}
      variant="solid"
      className={`flex ${props.className || ""}`}
    >
      <ArrowLeftCircleIcon className="h-4" /> Back
    </TrayButton>
  );
};

const Modal = (props: ModalProps) => {
  props = { ...defaultButtonText, ...props };
  const showCloseButton =
    typeof props.showCloseButton !== "undefined" ? props.showCloseButton : true;

  const variant = props.variant || "small";
  const widthClasses = {
    xlarge: "w-screen max-w-screen-xl",
    large: "min-w-full md:min-w-[480px]",
    medium: "md:min-w-[480px]",
    small: "max-w-md",
  };
  const widthClass = widthClasses[variant];
  const isSmall = variant === "small" || variant === "medium";

  // These distinctions are needed because there can be sticky divs at either
  // the top or bottom, which need their own rounded declaration since they
  // sit on top of the parent container
  const roundedClass = isSmall ? "rounded-xl" : "md:rounded-md";
  const roundedBottomClass = isSmall ? "rounded-b-xl" : "md:rounded-b-md";
  const roundedTopClass = isSmall ? "rounded-t-xl" : "md:rounded-t-md";

  const ConfirmationButtons = () => {
    return (
      <div className="flex gap-2 justify-center border-gray-200">
        {showCloseButton && (
          <PrimaryButton
            variant={"borderless"}
            onClick={props.onClose}
            className="w-full max-w-md"
          >
            {props.closeButtonText}
          </PrimaryButton>
        )}
        {props.onSubmit && props.linkButtonText && (
          <PrimaryButton
            className={`w-full`}
            variant={"solid"}
            onClick={props.onSubmit}
          >
            {props.linkButtonText}
          </PrimaryButton>
        )}
      </div>
    );
  };

  const Buttons = () => {
    return (
      <div className={`flex gap-2 justify-center border-gray-200`}>
        {showCloseButton && (
          <PrimaryButton
            className={`w-full`}
            variant={isSmall ? "solid" : "outline"}
            onClick={props.onClose}
            data-testid="email-close-button"
          >
            {props.closeButtonText}
          </PrimaryButton>
        )}
        {props.onSubmit && props.linkButtonText && (
          <PrimaryButton
            type="submit"
            onClick={props.onSubmit}
            className="w-full max-w-md"
          >
            {props.linkButtonText}
          </PrimaryButton>
        )}
      </div>
    );
  };

  return (
    <ModalWrapper onClose={props.onClose} modalOpen={props.modalOpen}>
      <div
        className={`fixed inset-0 overflow-y-auto`}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div
          className={`flex min-h-full w-full ${
            isSmall ? "p-4" : "md:p-4"
          } items-center justify-center`}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-0"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-0"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Panel
              className={`${widthClass} bg-white ${roundedClass} transition`}
            >
              <div
                className={`md:max-h-[95vh] relative transform ${
                  props.removeOverflowAutoClass ? "" : "md:overflow-y-auto"
                } align-middle shadow-xl transition-all ${props.className}`}
              >
                {isSmall && (
                  <div className="px-6 pt-3 pb-0 flex flex-col items-end">
                    <XButton
                      onClose={props.onClose}
                      className="translate-x-4 -translate-y-1"
                      dataTestId={props.dataTestId}
                    />
                    <h4 className="mt-0 w-full text-xl text-left mb-3">
                      {props.title}
                    </h4>
                  </div>
                )}

                {!isSmall && (
                  <div
                    className={`sticky top-0 md:top-0 p-1 sm:p-6  justify-start flex flex-col-reverse md:flex-row bg-white z-30 ${roundedTopClass}`}
                  >
                    <h4 className="text-xl md:text-4xl my-0 font-serif flex-grow text-center">
                      {props.title}
                    </h4>
                    <div>
                      <BackButton
                        onClose={props.onClose}
                        className="md:hidden mb-2 mt-10 ml-3"
                      />
                    </div>
                    <XButton
                      onClose={props.onClose}
                      className="hidden md:flex translate-x-4 -translate-y-1"
                      dataTestId={props.dataTestId}
                    />
                  </div>
                )}
                <div className="px-6 pb-6">{props.children}</div>
                {!props.omitBottomButtons && (
                  <div
                    className={`bg-white sticky bottom-0 px-6 py-4 border-t border-keyline-1 ${roundedBottomClass}`}
                  >
                    {!props.isConfirmation && <Buttons />}
                    {props.isConfirmation && <ConfirmationButtons />}
                  </div>
                )}
              </div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </div>
    </ModalWrapper>
  );
};

export default Modal;
