import Link from "next/link";
import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/solid";
import {
  AssetType,
  getImageUrl,
  getUnderConstruction,
} from "@lib/utilities/shared-utilities";
import { ProductCategoryConfigMap } from "@lib/model/product-category-config";
import EventTracker, { Events } from "@lib/tracking/event-tracker";
import ImageWithFallback from "@components/common/image-with-fallback";
import { HeaderLink, MenuMainContents } from "./header";

const HeaderMenu = ({ configMap }: { configMap: ProductCategoryConfigMap }) => {
  const [open, setOpen] = useState(false);

  const activeCategories = Object.values(configMap).filter(
    (category) => !getUnderConstruction(category, "landingPages")
  );

  return (
    <>
      <button
        className="md:hidden order-2 bg-transparent"
        onClick={() => {
          setOpen(!open);
          EventTracker.track(Events.MobileSiteNavOpen, {});
        }}
      >
        <ImageWithFallback
          className="max-h-6"
          alt="Open the navigation menu"
          src={getImageUrl("menu.svg", AssetType.Icon)}
          dataTestId="header-menu-button"
        />
      </button>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-0"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-0"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-900 bg-opacity-50 backdrop-blur-sm transition-opacity" />
          </Transition.Child>

          <Transition.Child
            as={Fragment}
            enter="transform transition ease-in-out duration-100 sm:duration-700"
            enterFrom="translate-x-full"
            enterTo="translate-x-0"
            leave="transform transition ease-in-out duration-100 sm:duration-700"
            leaveFrom="translate-x-0"
            leaveTo="translate-x-full"
          >
            <Dialog.Panel className="w-screen fixed inset-y-0 right-0 overflow-y-auto bg-white">
              <div className="flex justify-between items-center px-4 my-6">
                <Dialog.Title>
                  <Link
                    href="/"
                    data-testid="header-logo"
                    onClick={() => setOpen(false)}
                  >
                    <ImageWithFallback
                      className="h-6"
                      alt="PerfectRec logo"
                      src={getImageUrl("logo-dark.svg", AssetType.Image)}
                    />
                  </Link>
                </Dialog.Title>
                <button
                  type="button"
                  className="text-gray-900 focus:outline-none focus:ring-2 focus:ring-gray-400"
                  onClick={() => {
                    setOpen(false);
                    EventTracker.track(Events.MobileSiteNavClose, {});
                  }}
                >
                  <span className="sr-only">Close panel</span>
                  <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <div className="flex flex-col border-b border-gray-100 pb-6">
                <MenuMainContents
                  categories={activeCategories}
                  mobile
                  closeParent={() => setOpen(false)}
                />
              </div>
              <div className="flex flex-col text-gray-500 pt-2">
                <HeaderLink href="/about">About</HeaderLink>
                <HeaderLink href="/faq">FAQ</HeaderLink>
                <HeaderLink href="/posts">Blog</HeaderLink>
                <HeaderLink href="/how-it-works">How it works</HeaderLink>
                <HeaderLink href="/careers">Careers</HeaderLink>
              </div>
            </Dialog.Panel>
          </Transition.Child>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default HeaderMenu;
