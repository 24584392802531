import { Fragment, createContext } from "react";
import React from "react";
import { Dialog, Transition } from "@headlessui/react";

export interface ModalWrapper {
  children: React.ReactNode;
  onClose: () => void;
  modalOpen: boolean;
}

// This context lets deep children of modal surfaces know they're displaying in
// a modal.
export const ModalContext = createContext<{ isModal: boolean; open: boolean }>(
  null
);

const ModalWrapper = (props: ModalWrapper) => {
  return (
    <ModalContext.Provider value={{ isModal: true, open: props.modalOpen }}>
      <Transition appear show={props.modalOpen} as={Fragment}>
        <Dialog as="div" className={`relative z-30`} onClose={props.onClose}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-neutral-900 bg-opacity-50 transition-opacity" />
          </Transition.Child>
          {props.children}
        </Dialog>
      </Transition>
    </ModalContext.Provider>
  );
};

export default ModalWrapper;
