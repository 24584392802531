const LinkedInIcon = (props: { className?: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 16 16"
    className={`${props.className || ""}`}
  >
    <path
      fill="#9CA3AF"
      d="M14 1H1.969C1.437 1 1 1.469 1 2.031V14c0 .563.438 1 .969 1H14c.531 0 1-.438 1-1V2.031C15 1.47 14.531 1 14 1ZM5.219 13H3.156V6.344H5.22V13ZM4.187 5.406c-.687 0-1.218-.531-1.218-1.187A1.22 1.22 0 0 1 4.188 3c.656 0 1.187.563 1.187 1.219 0 .656-.531 1.187-1.188 1.187ZM13 13h-2.094V9.75c0-.75 0-1.75-1.062-1.75-1.094 0-1.25.844-1.25 1.719V13H6.53V6.344H8.5v.906h.031c.281-.531.969-1.094 1.969-1.094 2.094 0 2.5 1.407 2.5 3.188V13Z"
    />
  </svg>
);

export { LinkedInIcon };
