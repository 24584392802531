/**
 * When a product category's recommender is ready, it must be removed from this list.
 */
export const comingSoonManifest = [
  {
    label: "Smart Watches",
    image: {
      file: "PR_Homepage_CategoryNavigationImg_SmartWatches.jpg",
      alt: "Image of a smart watch",
      dimensions: {
        width: 300,
        height: 240,
      },
    },
    mailchimpTag: "Smart Watches Waitlist",
  },
];
