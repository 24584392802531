import { ArrowSmallRightIcon } from "@heroicons/react/24/outline";
import Link from "next/link";
import { useState } from "react";
import { ShowMoreBtn } from "./button";

interface ArticleCard {
  title: string;
  date?: string;
  author?: string;
  url: string;
  key: string | number;
  getVisibilityClass?: (i: number) => "block" | "hidden";
  i?: number;
}

const ArticleList = ({
  items,
  n = 6,
  className = "",
}: {
  items: ArticleCard[];
  n?: number;
  className?: string;
}) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <div className={`flex flex-col gap-4 ${className}`}>
      <div className="grid sm:grid-cols-2 gap-2 md:gap-4">
        {items.map((item, i) => (
          <ArticleCard
            key={item.key}
            i={i}
            {...item}
            getVisibilityClass={(i) => {
              return i < n || expanded ? "block" : "hidden";
            }}
          />
        ))}
      </div>
      {items.length > n && <ShowMoreBtn {...{ expanded, setExpanded }} />}
    </div>
  );
};

export const ArticleCard = ({
  url,
  title,
  i,
  author,
  date,
  getVisibilityClass,
}: ArticleCard) => {
  return (
    <Link
      href={url}
      className={`font-normal ${
        i !== undefined && getVisibilityClass ? getVisibilityClass(i) : ""
      }`}
    >
      <div className="rounded-lg flex justify-between p-4 border border-gray-500 gap-2 hover:bg-gray-50">
        <div>
          <h3 className="text-base line-clamp-1 overflow-hidden">{title}</h3>

          {author && (
            <div className="text-sm text-gray-500">
              <span>{author}</span>
              {date && (
                <>
                  <span className="mx-2">·</span>
                  <span>{date}</span>
                </>
              )}
            </div>
          )}
        </div>
        <ArrowSmallRightIcon className="h-4 shrink-0" />
      </div>
    </Link>
  );
};

export default ArticleList;
