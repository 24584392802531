import React, { useContext, useEffect, useState } from "react";
import Link from "next/link";
import { useRouter } from "next/router";
import {
  AssetType,
  getCategoryImage,
  getCategoryProductsUrl,
  getCompareIndexUrl,
  getImageUrl,
  getRecommenderLandingUrl,
  getScenarioIndexUrl,
  getScenarioUrl,
  getUnderConstruction,
} from "@lib/utilities/shared-utilities";
import { Disclosure, Popover } from "@headlessui/react";
import HeaderMenu from "@components/frame/header-menu";
import {
  ProductCategoryConfig,
  ProductCategoryConfigMap,
} from "@lib/model/product-category-config";
import {
  ReflowModalContext,
  ReflowModalContextProvider,
} from "@components/common/recommender-reflow";
import ImageWithFallback from "@components/common/image-with-fallback";
import { FadeScaleTransition } from "@components/common/transitions";
import { ProductCombinationCard } from "@components/product/product-combinations";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import eventTracker, { Events } from "@lib/tracking/event-tracker";
import { ArticleCard } from "@components/common/article-list";

const Header = ({ configMap }: { configMap: ProductCategoryConfigMap }) => {
  const { query, isReady, asPath } = useRouter();

  // Either done page or share page.
  const isDonePage = query.done === "1" || asPath.split("/")[1] === "s";
  const [stickyClass, setStickyClass] = useState("sticky");

  // We have a custom sticky header for the done page.
  useEffect(() => {
    if (isReady && isDonePage) {
      setStickyClass("md:sticky");
    }
  }, [isReady, isDonePage]);

  const activeCategories = Object.values(configMap);

  return (
    <Disclosure
      as="nav"
      className={`border-b border-b-gray-200 bg-white top-0 z-30 ${stickyClass}`}
    >
      <div className="container">
        <ReflowModalContextProvider>
          <div className="flex h-10 md:h-auto justify-between">
            <div className="flex flex-shrink-0 items-center gap-4">
              <Link href="/" data-testid="header-logo">
                <ImageWithFallback
                  alt="PerfectRec logo"
                  className="h-6"
                  src={getImageUrl("logo-dark.svg", AssetType.Image)}
                />
              </Link>
              <div className="hidden md:flex">
                <MenuMainContents categories={activeCategories} />
              </div>
            </div>
            <div className="hidden md:flex">
              <span className="flex self-center w-px h-8 bg-gray-200 my-2 mx-2" />
              <HeaderDropdown
                title="About"
                trackingItem="about"
                mainLinks={[
                  { title: "About", url: "/about" },
                  { title: "FAQs", url: "/faq" },
                ]}
              />
              <HeaderLink href="/posts">Blog</HeaderLink>
            </div>

            <HeaderMenu configMap={configMap} />
          </div>
        </ReflowModalContextProvider>
      </div>
    </Disclosure>
  );
};

/**
 * A link with the image for a category. Usually used inside other structures.
 */
const ImageLink = ({
  title,
  url,
  image,
  onClick,
}: {
  title;
  url;
  image;
  onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
}) => {
  return (
    <a
      href={url}
      className={`whitespace-nowrap flex items-center gap-4 p-3 min-h-[72px] hover:bg-gray-50 rounded-xl`}
      onClick={onClick}
    >
      <ImageWithFallback
        src={image}
        className="object-contain max-h-[48px] max-w-[48px] shrink-0"
        alt={`Image of ${title}`}
      />
      <span>{title}</span>
    </a>
  );
};

/**
 * Gets link-related display variables for a list of categories.
 */
export const getCategoryLinks = (
  linkResolver: (category: ProductCategoryConfig) => string,
  categories: ProductCategoryConfig[]
) => {
  return categories.map((category) => ({
    title: category.label,
    url: linkResolver(category),
    image: getCategoryImage(category),
    category,
  }));
};

/**
 * A regular header link.
 */
export const HeaderLink = ({
  href,
  children,
}: {
  href: string;
  children: React.ReactNode;
}) => {
  return (
    <Link
      href={href}
      className={`whitespace-nowrap text-base font-semibold p-4`}
    >
      {children}
    </Link>
  );
};

/**
 * A header link containing a dropdown.
 */
export const HeaderDropdown = ({
  title,
  dropdownTitle,
  mainLinks,
  children,
  mobile = false,
  reflow = false,
  closeParent,
  trackingItem,
}: {
  title: string;
  dropdownTitle?: string;
  mainLinks?: {
    title: string;
    url: string;
    image?: string;
    category?: ProductCategoryConfig;
  }[];
  children?: React.ReactNode;
  mobile?;
  reflow?;
  closeParent?: () => void;
  trackingItem: string;
}) => {
  const menuPositioningClasses = children
    ? "-right-28 xl:right-auto xl:left-0"
    : "-left-0";
  const btnFlexClass = mobile ? "justify-between" : "";
  const rightSideRoundedClass = mobile ? "rounded-b-md" : "rounded-r-md";
  const layoutDrivenPanelClass = mobile ? "flex-col mx-2" : "absolute flex";

  const { handleRecommenderClick } = useContext(ReflowModalContext);

  return (
    <Popover className={`relative`} as="div">
      {({ open, close: closePopover }) => (
        <>
          <Popover.Button
            className={`flex items-center ${btnFlexClass} text-base font-semibold gap-1 px-4 py-2 my-2 w-full focus:outline-none hover:bg-gray-50 rounded-xl`}
            onClick={() =>
              eventTracker.track(Events.TopCategoryNavClick, {
                item: trackingItem,
                action: open ? "closing" : "opening",
              })
            }
          >
            {title}
            <ChevronDownIcon
              className={`w-4 h-4 shrink-0 stroke-[2px] transition ${
                open && "rotate-180"
              }`}
            />
          </Popover.Button>
          <FadeScaleTransition>
            <Popover.Panel
              as="div"
              className={`bg-white gap-4 ${menuPositioningClasses} ${layoutDrivenPanelClass} top-[90%] z-30 rounded-md shadow-lg ring-1 ring-black ring-opacity-5`}
            >
              <div className="px-5 py-6">
                {dropdownTitle && (
                  <DropdownSubheading>{dropdownTitle}</DropdownSubheading>
                )}

                <ul>
                  {mainLinks.map(({ title, url, image, category }) => (
                    <li key={url}>
                      {image && category ? (
                        <ImageLink
                          {...{ title, url, image }}
                          onClick={(e) => {
                            if (reflow) {
                              handleRecommenderClick(category, e);
                            }
                            closePopover();
                            if (closeParent) {
                              closeParent();
                            }
                          }}
                        />
                      ) : (
                        <Link
                          href={url}
                          className="p-3 block hover:bg-gray-50 rounded-md"
                          onClick={() => closePopover()}
                        >
                          {title}
                        </Link>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
              {children && (
                <div
                  className={`px-5 py-6 bg-gray-100 md:min-w-[420px] ${rightSideRoundedClass}`}
                >
                  {children}
                </div>
              )}
            </Popover.Panel>
          </FadeScaleTransition>
        </>
      )}
    </Popover>
  );
};

/**
 * All-caps subheading for inside of a dropdown.
 */
export const DropdownSubheading = ({
  children,
}: {
  children: React.ReactNode;
}) => (
  <div className="small-caps text-lg text-blue-600 font-semibold whitespace-nowrap mb-3">
    {children}
  </div>
);

/**
 * The main category-driven links for the menu. Used by desktop and mobile layouts.
 */
export const MenuMainContents = ({
  categories,
  mobile = false,
  closeParent,
}: {
  categories: ProductCategoryConfig[];
  mobile?;
  closeParent?: () => void;
}) => {
  return (
    <>
      <HeaderLink href="/">Home</HeaderLink>
      <HeaderDropdown
        title="Browse"
        dropdownTitle="Product Catalog"
        mobile={mobile}
        mainLinks={getCategoryLinks(
          getCategoryProductsUrl,
          categories.filter(
            (category) => !getUnderConstruction(category, "landingPages")
          )
        )}
        closeParent={closeParent}
        trackingItem="browseProducts"
      />
      <HeaderDropdown
        title="Compare"
        dropdownTitle="Categories"
        mobile={mobile}
        mainLinks={getCategoryLinks(
          getCompareIndexUrl,
          categories.filter(
            (category) => !getUnderConstruction(category, "landingPages")
          )
        )}
        closeParent={closeParent}
        trackingItem="compareProducts"
      >
        <DropdownSubheading>Popular Comparisons</DropdownSubheading>
        <div className="flex flex-col gap-4">
          {categories
            .filter((category) => category.topComparison)
            .map((category) => (
              <ProductCombinationCard
                combination={category.topComparison}
                category={category}
                key={category.name}
              />
            ))}
        </div>
      </HeaderDropdown>
      <HeaderDropdown
        title="Recommend"
        dropdownTitle="Available Recommendations"
        mobile={mobile}
        mainLinks={getCategoryLinks(
          getRecommenderLandingUrl,
          categories.filter(
            (category) => !getUnderConstruction(category, "recommender")
          )
        )}
        reflow
        closeParent={closeParent}
        trackingItem="recommend"
      />
      <HeaderDropdown
        title="Articles"
        dropdownTitle="Articles"
        mobile={mobile}
        mainLinks={getCategoryLinks(
          getScenarioIndexUrl,
          categories.filter((category) => category.features.scenarios)
        )}
        trackingItem="scenarios"
        closeParent={closeParent}
      >
        <DropdownSubheading>Popular Articles</DropdownSubheading>
        <div className="flex flex-col gap-4">
          {categories
            .filter((category) => category.topScenario)
            .map((category) => (
              <ArticleCard
                i={0}
                title={category.topScenario.title}
                url={category.topScenario.url}
                key={category.topScenario.key}
              />
            ))}
        </div>
      </HeaderDropdown>
    </>
  );
};

export default Header;
