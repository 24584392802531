/* eslint-disable */
import Link from "next/link";
import {
  getImageUrl,
  AssetType,
  getRecommenderLandingUrl,
  getUnderConstruction,
  getCategoryProductsUrl,
  getCategoryFeatureState,
  getCompareIndexUrl,
  getCategoryScenariosUrl,
} from "@lib/utilities/shared-utilities";
import { DocumentDuplicateIcon } from "@heroicons/react/24/solid";
import { useContext } from "react";
import {
  RoundedBadge,
  RoundedBadgeColor,
} from "@components/common/rounded-badge";
import { NotificationContext } from "contexts/notification-context-provider";
import { LinkedInIcon } from "@components/svgs/linkedin-icon";
import { ProductCategoryConfigMap } from "@lib/model/product-category-config";
import { comingSoonManifest } from "@lib/coming-soon-products";
import ImageWithFallback from "@components/common/image-with-fallback";
import Script from "next/script";
import { useRouter } from "next/router";

const year = new Date().getFullYear();

const Footer = ({
  altContainer = false,
  className = "",
  configMap,
}: {
  altContainer?: boolean;
  className?: string;
  configMap: ProductCategoryConfigMap;
}) => {
  const contactEmail = "contact@perfectrec.com";

  const { setNotification } = useContext(NotificationContext);

  const router = useRouter();
  const embedAds = router.query.embedAds !== "false";

  const onCopyToClipboard = () => {
    navigator.clipboard.writeText(contactEmail);
    setNotification({ text: "Email Copied!", show: true });
    setTimeout(
      () => setNotification((prev) => ({ ...prev, show: false })),
      1000
    );
  };

  return (
    <footer id="footer" className="pt-6">
      <script
        type="text/javascript"
        async
        src="https://btloader.com/tag?o=5698917485248512&upapi=true&domain=perfectrec.com"
      ></script>
      {embedAds && (
        <Script
          id="raptive-footer"
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `!function(){"use strict";var e;e=document,function(){var t,n;function r(){var t=e.createElement("script");t.src="https://cafemedia-com.videoplayerhub.com/galleryplayer.js",e.head.appendChild(t)}function a(){var t=e.cookie.match("(^|[^;]+)\s*__adblocker\s*=\s*([^;]+)");return t&&t.pop()}function c(){clearInterval(n)}return{init:function(){var e;"true"===(t=a())?r():(e=0,n=setInterval((function(){100!==e&&"false" !== t || c(), "true" === t && (r(), c()), t = a(), e++}), 50))}}}().init()}();`,
          }}
        ></Script>
      )}
      <div
        className={`${
          altContainer ? "px-[4vw] md:px-0" : "container"
        } mb-6 ${className}`}
      >
        <div className="mb-6"></div>
        <div className="grid grid-cols-2 xl:grid-cols-4 gap-2">
          <div className="col-span-2 xl:col-span-1 mb-8 justify-self-center md:justify-self-start">
            <Link href="/" className="block">
              <ImageWithFallback
                className="h-6"
                alt="PerfectRec logo"
                src={getImageUrl("logo-dark.svg", AssetType.Image)}
              />
            </Link>
          </div>

          <div className="mb-8">
            <p className="text-base font-bold leading-7.5 text-gray-900 mb-0">
              Products
            </p>

            {/* List categories where recommender is ready */}
            <ul className="footer-link-list">
              {Object.entries(configMap)
                .filter(
                  ([key, category]) =>
                    !getUnderConstruction(category, "recommender")
                )
                .map(([key, category]) => (
                  <li key={key} className="flex items-center">
                    <Link
                      href={getRecommenderLandingUrl(category)}
                      legacyBehavior
                    >
                      {category.label}
                    </Link>
                  </li>
                ))}
            </ul>
          </div>

          <div className="mb-8">
            <p className="text-base font-bold leading-7.5 text-gray-900 mb-0">
              &nbsp;
            </p>

            <ul className="footer-link-list">
              {/* List categories from the separate "coming soon" manifest. */}
              {comingSoonManifest.map((csCategory, i) => (
                <li className="flex items-center" key={i}>
                  <p className="font-semibold text-gray-400 text-sm leading-8 mb-0">
                    {csCategory.label}
                  </p>
                  <RoundedBadge
                    badgeColor={RoundedBadgeColor.Blue100}
                    className="ml-2"
                  >
                    Coming Soon
                  </RoundedBadge>
                </li>
              ))}
            </ul>
          </div>

          <div className="mb-8">
            <p className="text-base font-bold leading-7.5 text-gray-900 mb-0">
              Explore
            </p>

            <ul className="footer-link-list">
              <li>
                <Link href="/posts">Blog</Link>
              </li>
              <li>
                <Link href="/about">About us</Link>
              </li>
              <li>
                <Link href="/faq">FAQ</Link>
              </li>
              <li>
                <Link href="/how-it-works">How it works</Link>
              </li>
              <li>
                {/* See https://github.com/vercel/next.js/issues/4477. This becomes a problem for the Greenhouse embed sadly. */}
                {/* eslint-disable @next/next/no-html-link-for-pages */}
                <a href="/careers">Careers</a>
              </li>
              <li>
                <a href="/media">Media</a>
              </li>
            </ul>
          </div>

          <div className="mb-8">
            <p className="text-base font-bold leading-7.5 text-gray-900 mb-0">
              Compare
            </p>

            {/* List categories that have VS pages ready */}
            <ul className="footer-link-list">
              {Object.entries(configMap)
                .filter(([key, category]) =>
                  getCategoryFeatureState(category, "vsPages")
                )
                .map(([key, category]) => (
                  <li key={key}>
                    <Link href={getCompareIndexUrl(category)} legacyBehavior>
                      {category.label}
                    </Link>
                  </li>
                ))}
            </ul>
          </div>

          <div className="mb-8">
            <p className="text-base font-bold leading-7.5 text-gray-900 mb-0">
              Browse
            </p>

            {/* List categories whose landing pages are ready to be public. */}
            <ul className="footer-link-list">
              {Object.entries(configMap)
                .filter(
                  ([key, category]) =>
                    !getUnderConstruction(category, "landingPages")
                )
                .map(([key, category]) => (
                  <li key={key}>
                    <Link
                      href={getCategoryProductsUrl(category)}
                      legacyBehavior
                    >
                      {category.label}
                    </Link>
                  </li>
                ))}
            </ul>
          </div>

          <div className="mb-8">
            <p className="text-base font-bold leading-7.5 text-gray-900 mb-0">
              Guides
            </p>

            {/* List categories whose landing pages are ready to be public. */}
            <ul className="footer-link-list">
              {Object.entries(configMap)
                .filter(([key, category]) =>
                  getCategoryFeatureState(category, "scenarios")
                )
                .map(([key, category]) => (
                  <li key={key}>
                    <Link href={getCategoryScenariosUrl(category)}>
                      {category.label}
                    </Link>
                  </li>
                ))}
            </ul>
          </div>

          <div className="mb-8">
            <div className="text-base font-bold leading-7.5 text-gray-900 mb-0">
              Get in touch
            </div>

            <button
              className="flex items-center mb-1"
              onClick={onCopyToClipboard}
            >
              <span className="font-semibold text-gray-400 hover:text-gray-500 cursor-pointer mb-1 text-sm">
                {contactEmail}
              </span>

              <DocumentDuplicateIcon className="h-4 ml-1 mr-2 flex-shrink-0 cursor-pointer" />
            </button>
            <ul className="flex">
              <li>
                <a
                  href="https://www.linkedin.com/company/perfectrec"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="w-5 h-5"
                >
                  <LinkedInIcon className="w-5 h-5 text-gray-400 mr-4" />
                </a>
              </li>
              <li>
                <a
                  href="https://twitter.com/perfect_rec"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <ImageWithFallback
                    alt="Twitter Icon"
                    tabIndex={0}
                    src={getImageUrl("twitter-fill.svg", AssetType.Icon)}
                    className="w-5 h-5 cursor-pointer"
                  />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className={`${altContainer ? "px-[4vw] md:px-0" : "container"}`}>
        <div className="border-t border-t-gray-200 mb-4"></div>
        <div className="flex flex-col md:flex-row items-center md:justify-between font-semibold text-xs text-center md:text-left text-gray-400 mb-8 md:mb-4">
          <p className="mb-2 md:mb-0">&copy; {year} PerfectRec, Inc.</p>
          <div className="flex items-center">
            <a
              href="/terms-of-use"
              className="font-semibold text-gray-400 text-sm leading-8 mb-0"
            >
              Terms
            </a>
            <p className="font-semibold text-gray-400 text-sm leading-8 mb-0 mx-2">
              •
            </p>
            <a
              href="/privacy"
              className="font-semibold text-gray-400 text-sm leading-8 mb-0"
            >
              Privacy
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export { Footer };
