import { ProductCombinationDisplayData } from "@lib/model/product";
import ImageWithFallback from "@components/common/image-with-fallback";
import Link from "next/link";
import { AssetType, getImageUrl } from "@lib/utilities/shared-utilities";
import { ProductCategoryConfig } from "@lib/model/product-category-config";

const ProductCombinations = ({
  combinations,
  className = "",
  testId = "product-combinations",
}: {
  combinations: ProductCombinationDisplayData[];
  className: string;
  testId?: string;
}) => {
  return (
    <div
      className={`grid sm:grid-cols-2 gap-2 ${className}`}
      data-testid={testId}
    >
      {combinations.map((combination, i) => (
        <ProductCombinationCard combination={combination} key={i} />
      ))}
    </div>
  );
};

export const ProductCombinationCard = ({
  combination: { images, titles, url, photoNames },
  category,
}: {
  combination: ProductCombinationDisplayData;
  category?: ProductCategoryConfig;
}) => {
  let usableImages = images;
  if (photoNames && category && !usableImages) {
    usableImages = photoNames.map((photoName) =>
      getImageUrl(
        photoName,
        AssetType.ProductImage,
        { width: 150 },
        category.name
      )
    );
  }

  return (
    <a href={url}>
      <div
        className={`rounded-xl border border-gray-500 px-3 py-2 flex gap-3 bg-white hover:bg-gray-50`}
      >
        <div className="relative flex gap-1.5 items-center w-24">
          <ProductImage src={usableImages[0]} productTitle={titles[0]} />
          <div className="rounded-full font-bold bg-white w-7 h-7 absolute right-1/2 bottom-1/2 translate-x-[50%] translate-y-[50%] flex items-center justify-center text-sm">
            VS
          </div>
          <ProductImage src={usableImages[1]} productTitle={titles[1]} />
        </div>
        <div className="font-semibold">
          <div className="">
            {titles[0]} <span className="font-normal">vs</span>
          </div>
          <div className="">{titles[1]}</div>
        </div>
      </div>
    </a>
  );
};

const ProductImage = ({
  src,
  productTitle,
}: {
  src: string;
  productTitle: string;
}) => {
  return (
    <ImageWithFallback
      src={src}
      width={175}
      alt={productTitle}
      className="object-contain max-h-12 max-w-[48px]"
    />
  );
};

export default ProductCombinations;
