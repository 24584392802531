import Header from "@components/frame/header";
import { Footer } from "@components/page/footer";
import { ProductCategoryConfigMap } from "@lib/model/product-category-config";

const PageLayout = (
  props: {
    children: React.ReactNode;
    noFooter?: boolean;
    configMap: ProductCategoryConfigMap;
  } = {
    children: null,
    noFooter: false,
    configMap: null,
  }
) => {
  return (
    <>
      <Header configMap={props.configMap} />
      {props.children}
      {!props.noFooter && <Footer configMap={props.configMap} />}
    </>
  );
};

export { PageLayout };
