const enum RoundedBadgeColor {
  Blue100 = "bg-blue-100 text-blue-600",
  Blue600 = "bg-blue-600 text-white",
}

export interface RoundedBadgeProps {
  badgeColor: RoundedBadgeColor;
  children: React.ReactNode;
  className?: string;
}

const RoundedBadge = (props: RoundedBadgeProps) => {
  return (
    <span
      className={`inline-flex items-center leading-[0.813rem] rounded px-1 py-[1.5px] font-semibold text-2xs
      ${props.badgeColor}
      ${props.className || ""}`}
    >
      {props.children}
    </span>
  );
};

export { RoundedBadge, RoundedBadgeColor };
